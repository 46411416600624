<template>
  <div class="extrotec">
    <div style="position: relative;height:2rem">
      <img class="bannerImg" src="../assets/images/extrotec/banner.png" alt="" />
    </div>

    <div class="page_wrap">
      <div class="page_content">
        <div class="aboutus-bg" style='padding-top:0.3rem'>
          <img src='../assets/images/extrotec/aboutus-title.png' style='width:3.2rem;height:.5rem;margin:0 auto;display:block' />
          <div class="title">
            <span style="font-weight: bolder;">南京中科逆熵科技有限公司</span>致力于引领推动南京智算中心的算力应用，倾力打造<span
              style="color:#0061E3;"> “核心平台+ 数据运营+ 生态合作”</span>的人工智能产业生态圈，促进人工智能技术与传统产业结合，为解决方案供应商提供人工智能算力服务。</div>
          <div class="title1">
            中科逆熵公司是由南京麒麟科技创新园国资参股、南京智能计算中心指定的唯一运营企业，是中科寒武纪官方授权生态合作伙伴，与数十家科研院所、高校、人工智能生态企业有密切的合作关系，在人工智能算力平台运营、研发及生态拓展有丰富的经验，在深度学习、工业视觉、智慧交通、智慧安防等领域已完成多项发明专利和软件著作权，已注册“熵视”“ExtroVision”商标。公司已获得软件企业认证、高新技术企业认证、江苏省科技型中小企业认证和ISO9001质量管理体系认证，及“信息系统安全等级保护第三级”等资质。
          </div>
          <div class="video" >
            <video controls style="width: 100%;height: 100%;" src="../assets/images/extrotec/video.mp4"></video>
          </div>
        </div>
        <div class="aboutus-bg2">
            <img src='../assets/images/extrotec/companyTitle.png' style='width:3.2rem;height:.5rem;margin:.5rem auto;display:block' />
            <img src='../assets/images/extrotec/line.png' style='width:8.6rem;height:7.1rem;margin:0 auto;display:block' />
            <div class='content-absolut'>
              <div class='content-wrap position1' >
                  <div v-for='item in list2023' :key='item.index'>
                    <span class='content-titleMonth'>{{ item.month + '月'}}</span>
                    <span class='content-content' v-html='item.content'></span>
                  </div>
              </div>
              <div class='content-wrap position2' >
                  <div v-for='item in list2022' :key='item.index'>
                    <span class='content-titleMonth'>{{ item.month + '月'}}</span>
                    <span class='content-content' v-html='item.content'></span>
                  </div>
              </div>
              <div class='content-wrap position3' >
                  <div v-for='item in list2021' :key='item.index'>
                    <span class='content-titleMonth'>{{ item.month + '月'}}</span>
                    <span class='content-content' v-html='item.content'></span>
                  </div>
              </div>
              <div class='position4'>
                  <img src='../assets/images/extrotec/logo.png' style='width:.8rem;height:.8rem;position:relative;top:0.3rem;left:0.3rem;margin:0 0.5rem'/>
                  <span  class='content-content'>南京中科逆熵科技有限公司成立</span>
              </div>
            </div>
        </div>
        <div class="aboutus-bg3">
          <img src="../assets/images/extrotec/title-3.png" />
          <div class="pic">
            <div class="pic-item pic-item-1"></div>
            <div style="width:15px;display: inline-block;"></div>
            <div class="pic-item pic-item-2"></div>
            <div style="width:15px;display: inline-block;"></div>
            <div class="pic-item pic-item-3"></div>
            <div style="width:15px;display: inline-block;"></div>
            <div class="pic-item pic-item-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
       data(){
          return{
            list2023:[
               { month:12, content:'<span>· 荣誉当选江苏省服务贸易协会常务理事单位' + '</span><br/>' + '<span style="margin-left:0.36rem">·“基于国产MLU芯片一站式智能训练平台V1.0”获软件著作权</span>', id:0 },
               { month:11, content:'<span>· 通过江苏省高新技术企业认定</span> <br/><span style="margin-left:0.36rem">· 获第二届全球数字贸易博览会先锋奖DT2023银奖</span><br/><span style="margin-left:0.36rem">· 发明专利“一种基于二维码的单目摄像头测量物体尺寸</span>', id:1 },
               { month:9, content:'· 再次荣获软件企业证书 \n· 首批全国工业互联网行业产教融合共同体常务理事单位', id:2 },
               { month:6, content:'· 南京智能计算中心荣获国家首批新一代人工智能公共算力开放创新平台', id:3 },
               { month:4, content:'· 南京智能计算中心春季推介会成功举办', id:4 },
            ],
            list2022:[
               { month:12, content:'· 商标“ExtroVison”注册登记公开', id:0 },
               { month:10, content:'· 商标“熵视”注册登记公开', id:1 },
               { month:8, content:'·“边缘计算盒Ebox310”通过3C认证', id:2 },
               { month:7, content:'·“工业视觉AI检测平台V1.0”获软件著作权', id:3 },
               { month:3, content:'·“边缘智能盒子（extrobox210）”获外观专利授权', id:4 },
               { month:2, content:'· “AI教学盒子（extrobox202）”获外观专利授权', id:5 },
            ],
            list2021:[
               { month:12, content:'· 南京智能计算中心获信息安全等级保护第三级认证备案', id:0 },
               { month:9, content:'· 首次荣获软件企业证书', id:1 },
               { month:8, content:'· 获江苏省科技中小型企业认证入库', id:2 },
               { month:7, content:'· 南京智能计算中心第一期完成并正式运营', id:3 },
               { month:4, content:'· 通过ISO9001质量管理体系认证', id:4 },
            ],
          }
       }
    }
</script>

<style lang="scss" scoped>
.extrotec {
  background: #F5F5F5;

  .bannerImg {
    height: 2rem;
  }

  .page_wrap {
    .page_content {
      margin: 0 auto;
      width: 100%;
      max-width: 100%;

      .aboutus-bg {
        width: 100%;
        height: 9.5rem;
        background-image: url('../assets/images/extrotec/about-us-bg.png');
        background-size: cover;
        background-repeat:no-repeat;
        .title {
          padding-top: .34rem;
          width: 12rem;
          margin: 0 auto;
          font-weight: 400;
          font-size: 0.16rem;
          color: #333333;
          line-height: 0.24rem;
          text-indent: 2em;
          text-align: justify;
        }

        .title1 {
          padding-top: 0.15rem;
          width: 12rem;
          margin: 0 auto;
          font-size: 0.16rem;
          color: #333333;
          line-height: 0.24rem;
          text-indent: 2em;
          text-align: justify;
        }

        .video {
          margin: 0 auto;
          margin-top: 0.24rem;
          width: 11rem;
          height: 6.18rem;

          background: #010001;
          border-radius: 4px;
        }
      }

      .aboutus-bg2 {
        width: 100%;
        height: 10.2rem;
        background-image: url('../assets/images/extrotec/bg2-new.png');
        background-size: cover;
        background-position: center;
        font-size:0.14rem;
        position:relative;
        .content-absolut{
           line-height:0.26rem;
        }
        .position1{
            position:absolute;
            left:10.5rem;
            top:1.4rem;
        }
        .position2{
            position:absolute;
            left:8.8rem;
            top:4.1rem;
        }
        .position3{
            position:absolute;
            left:7.2rem;
            top:6.4rem;
        }
        .position4{
            position:absolute;
            left:4.2rem;
        }
        .content-titleMonth{
            color:#333;
            font-weight:600;
            margin-right:0.08rem;
            font-family:'黑体';
            display:inline-block;
            width:0.28rem;
        }
        .content-content{
            color:#606266;
        }
      }

      .aboutus-bg3 {
        max-width: 19.20rem;
        min-width: 12rem;
        margin: 0 auto;

        img {
          display: block;
          margin: 0 auto;
          width: 3.12rem;
          height: .52rem;
          margin-top: .64rem;
        }

        .pic {
          margin: 0 auto;
          margin-top: .28rem;
          margin-bottom: .64rem;
          width: 12rem;
          display:flex;
          .pic-item {
            display: inline-block;
            width: 2.8rem;
            height: 2rem;
            background-size: cover;
            background-position: center;
          }

          .pic-item-1{
            background-image: url(../assets/images/extrotec/pic1.png);
          }
          .pic-item-2{
            background-image: url(../assets/images/extrotec/pic2.png);
          }
          .pic-item-3{
            background-image: url(../assets/images/extrotec/pic3.png);
          }
          .pic-item-4{
            background-image: url(../assets/images/extrotec/pic4.png);
          }
        }
      }
    }
  }
}
</style>